import { OrderStatus, PaymentMethod, PaymentStatus } from "../../@api/dto"
import { OrderItemStockAdjustmentStatus } from "../../@api/dto/order.dto"

export const ORDER_ITEM_ADJUSTMENT_STATUS_OPTIONS = {
  [OrderItemStockAdjustmentStatus.Pending]: {
    fontColor: '#975CED',
    backgroundColor: 'rgba(151, 92, 237, 0.2)'
  },
  [OrderItemStockAdjustmentStatus.Confirmed]: {
    fontColor: '#767676',
    backgroundColor: 'rgba(118, 118, 118, 0.2)'
  },
  [OrderItemStockAdjustmentStatus.Rejected]: {
    fontColor: '#F80F00',
    backgroundColor: 'rgba(248, 15, 0, 0.2)'
  }
}

export const STATUS_OPTIONS = {
  [OrderStatus.READY_FOR_PICKUP]: {
    fontColor: '#B4207C',
    backgroundColor: '#F4DBEB',
    width: '130px'
  },
  [OrderStatus.DROP_OFF]: {
    fontColor: '#975CED',
    backgroundColor: 'rgba(151, 92, 237, 0.2)'
  },
  [OrderStatus.NEW]: {
    fontColor: '#B4207C',
    backgroundColor: '#F4DBEB'
  },
  [OrderStatus.COMPLETED]: {
    fontColor: '#767676',
    backgroundColor: 'rgba(118, 118, 118, 0.2)'
  },
  [OrderStatus.CANCELLED]: {
    fontColor: '#F80F00',
    backgroundColor: 'rgba(248, 15, 0, 0.2)'
  },
  [OrderStatus.REFUND]: {
    fontColor: 'white',
    backgroundColor: '#32c5ff'
  },
  [OrderStatus.REJECTED]: {
    fontColor: '#F80F00',
    backgroundColor: 'rgba(248, 15, 0, 0.2)'
  },
  [OrderStatus.SHIPPING]: {
    fontColor: '#E86D46',
    backgroundColor: 'rgba(232, 109, 70, 0.2)'
  },
  [OrderStatus.ACCEPTED]: {
    fontColor: '#4C9FF0',
    backgroundColor: 'rgba(76, 159, 240, 0.2)'
  }
}

export const PAYMENT_STATUS_OPTIONS = {
  [PaymentStatus.PAID]: {
    fontColor: 'white',
    backgroundColor: '#009900'
  },
  [PaymentStatus.UNPAID]: {
    fontColor: 'white',
    backgroundColor: '#FFA500'
  }
}

export const PAYMENT_METHOD_OPTIONS = {
  [PaymentMethod.COD]: {
    fontColor: 'white',
    backgroundColor: '#00d68f',
    width: '120px',
    height: '40px'
  },
  [PaymentMethod.PAY_WAY]: {
    fontColor: 'white',
    backgroundColor: '#00d68f'
  }
}
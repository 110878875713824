import { DiscountStatus } from "../../@api/dto"


export const DISCOUNT_STATUS_OPTIONS = {
  [DiscountStatus.PENDING]: {
    fontColor: '#E86D46',
    backgroundColor: 'rgba(232, 109, 70, 0.2)'
  },
  [DiscountStatus.APPROVED]: {
    fontColor: '#B4207C',
    backgroundColor: '#F4DBEB'
  },
  [DiscountStatus.REJECTED]: {
    fontColor: '#767676',
    backgroundColor: 'rgba(118, 118, 118, 0.2)'
  },
  [DiscountStatus.EXPIRED]: {
    fontColor: '#767676',
    backgroundColor: 'rgba(118, 118, 118, 0.2)'
  }
}

import { FlashSaleStatus } from "../../@api/dto/admin-flash-sale.dto"

export const STATUS_OPTIONS = {
  [FlashSaleStatus.Upcoming]: {
    fontColor: '#F1960C',
    backgroundColor: 'rgba(253, 242, 227, 1)'
  },
  [FlashSaleStatus.Ongoing]: {
    fontColor: '#2CB44A',
    backgroundColor: 'rgba(44, 180, 74, 0.2)'
  },
  [FlashSaleStatus.Expired]: {
    fontColor: '#767676',
    backgroundColor: 'rgba(118, 118, 118, 0.2)'
  }
}

import { CollectionStatus } from "../../@api/dto/collection.dto"

export const STATUS_OPTIONS = {
  [CollectionStatus.Pending]: {
    fontColor: '#4C9FF0',
    backgroundColor: 'rgba(76, 159, 240, 0.2)'
  },
  [CollectionStatus.Reject]: {
    fontColor: '#F80F00',
    backgroundColor: 'rgba(248, 15, 0, 0.2)'
  },
  [CollectionStatus.Approved]: {
    fontColor: '#2CB44A',
    backgroundColor: 'rgba(44, 180, 74, 0.2)'
  }
}
